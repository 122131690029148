<template>
  <div>
    <defaultFieldTypes
      :field="field"
      :fieldAttributes="{ ...fieldAttributes, type: 'text', name: title }"
      v-on="$listeners"
      :templateContent="result"
      :value="value"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["result", "fieldAttributes", "field", "value"],

  computed: {
    title() {
      let title;
      if (this.result.valueLine.cmd == "send") {
        title = "Input";
      } else {
        title = "Expected Output";
      }
      return title;
    },
  },
};
</script>